@import "~antd/dist/antd.css";
@import "src/styles/variables";

.App {
  min-height: 100vh;
  background-color: #fff;
  > .sidebarWrapper {
    overflow: auto;
    width: $sideBarWidth;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #001529;
    transition: width $sideBarTransitionTime;
    &.isSidebarCollapsed {
      width: $sideBarCollapsedWidth;
    }
  }
  > .rightContent {
    margin-left: $sideBarWidth;
    transition: margin-left $sideBarTransitionTime;
    &.isSidebarCollapsed {
      margin-left: $sideBarCollapsedWidth;
    }
    > .header {
      height: $headerHeight;
      //line-height: $headerHeight;

      > .headerMainWrapper {
        height: $headerMainHeight;
        padding: 0 15px;
        .headerIcon {
          cursor: pointer;
          font-size: 18px;
          transform: rotate(0deg);
          transition: transform $sideBarTransitionTime;
          &:hover {
            color: $primary-color;
          }
          &.isSidebarCollapsed {
            transform: rotate(180deg);
          }
        }
      }

      > .tagsWrapper {
        height: calc(#{$headerHeight} - #{$headerMainHeight});
        padding: 0 15px;
        border-bottom: 1px solid #e6e6e6;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
          0 0 3px 0 rgba(0, 0, 0, 0.04);
      }
    }
    > .mainWrapper {
      min-height: calc(100vh - #{$headerHeight} - #{$footerHeight});
      padding: 15px;
      .visibility-hidden {
        visibility: hidden;
      }
      .display-none {
        display: none;
      }
    }
    > .footer {
      height: $footerHeight;
      line-height: $footerHeight;
      text-align: center;
      color: #999;
      font-size: 12px;
    }
  }
  .operation-column a,
  .operation-column > span {
    margin-right: 10px;
    color: $primary-color;
    cursor: pointer;
    &.warning {
      color: $warning-color;
    }
  }
}
