// base color
$blue: #324157;
$light-blue: #3a71a8;
$red: #c03639;
$pink: #e65d6e;
$green: #30b08f;
$tiffany: #4ab7bd;
$yellow: #fec171;
$panGreen: #30b08f;
$primary-color: #1890ff;
$warning-color: #faad14; // 警告色

//sidebar
$menuText: #bfcbd9;
$menuActiveText: #409eff;
$subMenuActiveText: #f4f4f5; //https://github.com/ElemeFE/element/issues/12951

//$menuBg:#304156;
//$menuHover:#263445;
$menuBg: #4a5b70;
$menuHover: #263445;

//$subMenuBg:#1f2d3d;
$subMenuBg: #1f2d3d;
$subMenuHover: #001528;

$logoWrapperHeight: 100px;

$highlightDanger: #f56c6c;
$highlightWarning: #e6a23c;
$highlightSuccess: #67c23a;
$highlightPrimary: #409eff;
$highlightInfo: #ccc; /*909399*/

//form label
$labelWidth: 110px;

//layout
$sideBarWidth: 210px;
$sideBarCollapsedWidth: 80px;
$sideBarTransitionTime: 0.2s;
$headerHeight: 84px;
$headerMainHeight: 50px;
$footerHeight: 64px;

/*{
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}*/

// the :export directive is the magic sauce for webpack
/*:export {
  menuText: $menuText;
  menuActiveText: $menuActiveText;
  subMenuActiveText: $subMenuActiveText;
  menuBg: $menuBg;
  menuHover: $menuHover;
  subMenuBg: $subMenuBg;
  subMenuHover: $subMenuHover;
  sideBarWidth: $sideBarWidth;
  labelWidth: $labelWidth;
}*/
