.headerMainWrapper {
  border-bottom: 1px solid #f0f2f5;
  .content-title {
    font-size: 20px;
    font-weight: 500;
  }
  .ant-dropdown-link {
    color: #40a9ff;
    cursor: pointer;
  }
}
