.order-logistics {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .anticon-arrow-right {
      font-size: large;
      color: blueviolet;
      margin: 0 50px;
    }
  }
}
