.order-detail {
  h3 {
    color: #bbb;
  }
  .expected-time {
    display: flex;
  }
  .send-logistics-btn {
    cursor: pointer;
  }
}
